<template>
  <div class="card" :class="$style.map">
    <div :class="$style.content" v-bind:style="isFleetPlan ? 'margin-right: 0;' : ''">
      <div v-if="!mapLoading" class="no-print" :class="$style.header">
        <p style="margin-bottom: 0">
          <span v-if="routeTarget !== 'vehicle'" >
          <span :class="$style.titleLabel">運転者：</span>
          <span :class="$style.titleValue">{{ driveData.user_name }}、
            <span v-if="routeTarget !== 'user'" >
              支店：{{ driveData.branch_name }}、 車両名：{{ driveData.vehicle_name }}、 ロガーID：{{ driveData.logger_id }}、
            </span>
            {{ driveData.date | momentDate}}
          </span>
          </span>
        </p>
      </div>
      <div class="no-print" :class="$style.contentHeader">
        <div class="card-header clearfix" style="box-shadow: 0px 3px 2px #00000029;">
          <div class="pull-right">
            <a-button v-if="panoVisible" type="primary" @click="closeStreetView">ストリートビューを閉じる</a-button>
          </div>
          <a-tabs defaultActiveKey="1" @change="tabChange">
            <a-tab-pane key="1">
              <span slot="tab">
                <a-icon type="environment"/>地図情報
              </span>
            </a-tab-pane>
            <a-tab-pane key="3" v-if="!isFleetPlan">
              <span slot="tab">
                <a-icon type="book"/>レポート
              </span>
            </a-tab-pane>
            <a-tab-pane key="4">
              <span slot="tab">
                <a-icon type="form"/>運転日報
              </span>
            </a-tab-pane>
            <a-tab-pane v-if="myRole === 0 && routeTarget === 'drive_data'" key="2">
              <span slot="tab">
                <a-icon type="eye"/>生データ
              </span>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="printArea" :class="$style.contentWrapper">
        <div v-show="myRole === 0 && activeTab === '2'" class="no-print" style="padding: 20px;">
          <p class="text-center" style="margin-right: 20px; margin-top: 20px;"><a-button :loading="rawDataButtonLoading" type="primary" @click="rawData">生データのダウンロード</a-button></p>
        </div>
        <div v-show="activeTab === '3' && !isFleetPlan" :class="$style.tabContainer">
          <p class="text-right no-print" style="margin-right: 20px; margin-top: 20px;"><a-button :class="$style.filledBtn" @click="print">印刷する</a-button></p>
          <div :class="$style.reportContainer">
            <h3 :class="$style.reportTitle">運転情報</h3>
            <div :class="$style.driveInfoContainer">
              <div :class="$style.leftInfo">
                <a-avatar shape="circle" icon="user" :size="56" :class="$style.avatar"/>
                <div :class="$style.driverInfo" v-if="routeTarget !== 'vehicle'">
                  <span :class="$style.label">運転者:</span><span :class="$style.value">{{ driveData.user_name }}</span>
                </div>
                <div :class="$style.driverInfo" v-else>
                  <span :class="$style.label">車両名:</span><span :class="$style.value">{{ driveData.vehicle_name }}</span>
                  <span :class="$style.label">車両番号:</span><span :class="$style.value">{{ driveData.vehicle_number }}</span>
                </div>
              </div>
              <div :class="$style.rightInfo">
                <div :class="$style.infoItem">
                  <span :class="$style.label">運転日時 : </span><span :class="$style.value">{{ driveData.time_start | momentDateTime }} 〜 {{ driveData.time_end | momentTime}}</span>
                </div>
                <div :class="$style.infoItem">
                  <span :class="$style.label">運転時間 : </span><span :class="$style.value">{{ reportValue.driving_time }}</span>
                </div>
                <div :class="$style.infoItem">
                  <span :class="$style.label">走行距離 : </span><span :class="$style.value">{{ reportValue.distance.toFixed(3) }} km</span>
                </div>
              </div>
            </div>
            <h4>違反情報</h4>
            <div v-if="myUseAnalyze === 1" :class="$style.percentArea">
              <div :class="$style.percentContainer">
                <span :class="$style.percentLabel">遵守率:</span><span :class="$style.percentValue">{{ reportComplianceRate }} %</span>
              </div>
              <div :class="$style.numContainer">
                <i class="fa fa-ban"></i> <span :class="$style.numLabel">違反件数:</span>
                <span :class="$style.numValue">{{ reportTotalViolationNum }}</span>
                <i class="fa fa-info-circle" style="margin-left:16px;"></i> <span :class="$style.numLabel">規制件数:</span>
                <span :class="$style.numValue">{{ driveData.regulation_num }}</span>
              </div>
            </div>
            <a-table :columns="reportViolationsColumns" :data-source="reportViolations" :pagination="false" size="small"
            :rowClassName="(r, i) => i % 2 === 0 ? '' :  $style.stripe">
              <span slot="num" slot-scope="num">{{ num }} 回</span>
              <span slot="perHour" slot-scope="perHour">{{ perHour }} 回</span>
              <span slot="perKm" slot-scope="perKm">{{ perKm }} 回</span>
            </a-table>
            <div v-if="myUseAnnounce === 1" :class="$style.percentArea">
              <div :class="$style.percentContainer">
                <span :class="$style.percentLabel">アナウンス遵守率:</span><span :class="$style.percentValue">{{ reportComplianceRateAnnounce }} %</span>
              </div>
              <div :class="$style.numContainer">
                <i class="fa fa-ban"></i> <span :class="$style.numLabel">違反件数:</span>
                <span :class="$style.numValue">{{ reportTotalViolationAnnounceNum }}</span>
                <i class="fa fa-info-circle" style="margin-left:16px;"></i> <span :class="$style.numLabel">アナウンス件数:</span>
                <span :class="$style.numValue">{{ announceCount }}</span>
              </div>
            </div>
            <a-table :columns="reportViolationsAnnounceColumns" :data-source="reportViolationsAnnounce" :pagination="false" size="small"
            :rowClassName="(r, i) => i % 2 === 0 ? '' :  $style.stripe">
              <span slot="num" slot-scope="num">{{ num }} 回</span>
              <span slot="announce" slot-scope="announce">{{ announce }} 回</span>
            </a-table>
            <h4>地図情報</h4>
            <div ref="repoMapParent"></div>
          </div>
        </div>
        <div v-show="activeTab === '4'" :class="$style.tabContainer" class="daily-report">
          <p class="text-right no-print" style="margin-right: 20px; margin-top: 20px;"><a-button :class="$style.filledBtn" @click="print">印刷する</a-button></p>
          <div :class="$style.reportContainer">
            <h3 :class="$style.reportTitle">運転日報</h3>
            <div :class="$style.driveInfoContainer" style="margin-bottom: 32px;">
              <div :class="$style.leftInfo" style="vertical-align: top;">
                <div :class="$style.infoItem">
                  <span :class="$style.label">車両番号: </span><span :class="$style.value">{{ driveData.vehicle_number }}</span>
                </div>
                <div :class="$style.infoItem" v-if="routeTarget !== 'vehicle'">
                  <span :class="$style.label">運転者名: </span><span :class="$style.value">{{ driveData.user_name }}</span>
                </div>
              </div>
              <div :class="$style.rightInfo" style="vertical-align: top;">
                <div :class="$style.infoItem">
                  <span :class="$style.label">運転日時 : </span><span :class="$style.value">{{ driveData.time_start | momentDateTime }} 〜 {{ driveData.time_end | momentTime}}</span>
                </div>
                <div :class="$style.infoItem">
                  <span :class="$style.label">走行距離 : </span><span :class="$style.value">{{ reportValue.distance.toFixed(3) }} km</span>
                </div>
              </div>
            </div>

            <a-table :columns="reportSplitsColumns" :data-source="reportSplits" :pagination="false" size="small"
            :rowClassName="(r, i) => i % 2 === 0 ? '' :  $style.stripe" rowKey="id">
              <span slot="start_datetime" slot-scope="record"><span v-bind:class="[record.start_datetime !== record.start_datetime_origin ? 'color-red' : '']">{{ record.start_datetime | momentTime }}</span></span>
              <span slot="name" slot-scope="name">{{ name }}</span>
              <span slot="driving_purpose" slot-scope="driving_purpose">{{ driving_purpose ? drivingPurposes.find(e => e.id === driving_purpose).name : '' }}</span>
              <span slot="end_datetime" slot-scope="record"><span v-bind:class="[record.end_datetime !== record.end_datetime_origin ? 'color-red' : '']">{{ record.end_datetime | momentTime }}</span></span>
              <span slot="comment" slot-scope="comment">{{ comment }}</span>
              <span class="no-print" slot="edit" slot-scope="record"><a-button @click="updateSplit(record.id)">編集</a-button></span>
            </a-table>
          </div>
        </div>
        <div v-if="panoVisible && showTrajectory && !isFleetPlan" style="padding-left: 25px; padding-right: 25px; padding-bottom: 30px;">
          <a-slider
            v-model="sliderValue"
            :marks="sliderMarks"
            :max="totalGpsNum"
            :tip-formatter="sliderFormatter"
          />
        </div>
        <div id="mapWrapper" ref="mapWrapper" class="card-body" style="padding: 0 !important; position: relative">
          <GmapStreetViewPanorama
            ref="pano"
            clickToGo="true"
            addressControl="true"
            :position="streetViewPosition"
            :pov="streetViewPov"
            enableCloseButton="true"
            :visible="panoVisible"
            style="position: relative"
            :style="panoStyle"
          >
            <div v-if="panoVisible" style="position:absolute; top: 10px; right: 60px; background-color: #222; opacity: .8; font-family: Roboto,Arial; outline-color: #000; color: white; padding: 6px 8px; border-radius: 2px; text-align: left; z-index: 999; font-size: 16px;">
              <button v-if="showTrajectory" @click="prevSlider" style="margin-right: 10px; color: black; padding: 0 6px; font-size: 14px">&lt;</button>
              <span>{{ focusTime | momentDateTimeAdd9Hour }}: {{ focusSpeed }} km/h</span>
              <button v-if="showTrajectory" @click="nextSlider" style="margin-left: 10px; color: black; padding: 0 6px; font-size: 14px">&gt;</button>
            </div>
          </GmapStreetViewPanorama>
          <div
            id="resizer"
            ref="resizer"
            class="text-center"
            style="background: #ffffff; line-height: 0.8; cursor: row-resize"
            draggable="true"
            v-if="panoVisible"
            v-on="{ dragend: resizeEnd }"
          >
            <i class="icmn-flickr"></i>
          </div>
          <div ref="defaultMapParent">
            <div v-if="mapLoading" class="text-center" style="width: 100%; height: 80vh; padding-top: 200px">
              <a-spin tip="Loading..." />
            </div>
            <GmapMap
              id="googleMap"
              ref="gMap"
              :center="{lat:35.517007, lng:139.613112}"
              :zoom="15"
              map-type-id="roadmap"
              :options="{
                styles: mapStyle,
                streetViewControl: !isFleetPlan,
                disableDefaultUi: 'true'
              }"
              :style="gMapStyle"
            >
              <div v-if="!isFleetPlan">
                <GmapMarker
                  :key="m.id"
                  v-for="(m, index) in markers"
                  :visible="!m.hide"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :icon="m.icon"
                  @click="setView(index)"
                />
              </div>
              <div v-if="!isFleetPlan">
                <GmapMarker
                  :key="m.id"
                  v-for="(m) in announcementMarkers"
                  :position="m.position"
                  :draggable="false"
                  :icon="m.icon"
                  @click="setViewAnnounce(m.id)"
                />
              </div>
              <GmapMarker
                :key="'w' + index"
                v-for="(m, index) in warningMarkers"
                :visible="warningVisible"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                :icon="m.icon"
                @click="setViewWarning(index)"
              />
              <div v-if="showTrajectory">
                <GmapMarker
                  :key="'s' + index"
                  v-for="(m, index) in separationMarkers"
                  :position="m.position"
                  :clickable="false"
                  :draggable="false"
                  :icon="m.icon"
                />
              </div>
              <div v-if="showTrajectory">
                <GmapPolyline
                  :key="index"
                  v-for="(l, index) in lines"
                  :path="l.path"
                  :options="l.icons"
                />
              </div>
              <GmapPolyline
                ref="warningLine"
                :key="index+100000"
                v-for="(l, index) in warningLines"
                :path="l.path"
                :options="l.icons"
              />
              <gmap-info-window
                :options="{
                  maxWidth: 300,
                  pixelOffset: { width: 0, height: -35 }
                }"
                :position="infoWindow.position"
                :opened="infoWindow.open"
                @closeclick="infoWindow.open=false">
                <div v-html="infoWindow.template"></div>
              </gmap-info-window>
            </GmapMap>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isFleetPlan" class="no-print" :class="$style.sidebar">
      <div :class="$style.sidebarHeader">
        <a-button v-if="myRole <= 2" v-bind:type="edit ? 'default' : 'dashed'" style="width: 80px" @click="toggleEdit()">
          {{ editBtnName }}
        </a-button>
        <a-checkbox style="margin-left: 20px;" @change="onChangeWarning">
          危険箇所表示
        </a-checkbox>
      </div>
      <div :class="$style.tabs">
        <a-list item-layout="horizontal" :data-source="markers">
          <a-list-item :class="{ active: item.isActive, hide: item.hide }" slot="renderItem" slot-scope="item, index" style="padding-top: 8px; padding-bottom: 8px;" @click="setView(index)" v-bind:style="edit || !item.hide ? '' : 'display: none'">
            <a v-if="edit && !item.loading" slot="actions"><a-button @click="toggleHide(index)">{{ item.hide ? '戻す' : '非表示' }}</a-button></a>
            <a v-if="edit && item.loading" slot="actions"><a-spin tip="Loading..." /></a>
            <a-list-item-meta>
              <p slot="title" style="margin-bottom: 0; color: rgba(0, 0, 0, 0.45); ">{{ item.time | momentTimeAdd9Hour }} ({{ item.speed }} km/h)</p>
              <p slot="description" style="margin-bottom: 0; color: rgba(0, 0, 0, 0.85); ">
                <span v-html="violationText(item.title)"></span>
              </p>
              <a-avatar
                size="small"
                slot="avatar"
                :src="item.icon.url"
                style="margin-left: 12px; margin-top: 8px;"
              />
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>
    </div>
    <a-modal
      title="運転日報更新"
      :visible="visibleDrivingSplit"
      @cancel="handleCancelDrivingSplit"
    >
      <div>
        <a-form-model
          ref="drivingSplitRef"
          :rules="drivingSplitRules"
          :model="drivingSplitForm"
        >

          <a-config-provider :locale="locale">
            <a-form-model-item ref="drivingStartDate" label="出発日" prop="drivingStartDate">
              <a-date-picker placeholder="出発日" v-model="drivingStartDate" />
            </a-form-model-item>
          </a-config-provider>
          <a-config-provider :locale="locale">
            <a-form-model-item ref="drivingStartTime" label="出発時刻" prop="drivingStartTime">
              <a-time-picker placeholder="出発時刻" v-model="drivingStartTime" format="HH:mm:ss" />
            </a-form-model-item>
          </a-config-provider>
          <p>（元の出発日時: {{ drivingSplitOriginStart }}）</p>

          <a-form-model-item ref="name" label="行き先" prop="name">
            <a-input
              v-model="drivingSplitForm.name"
              @blur="
                () => {
                  $refs.name.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

          <a-form-model-item label="運転の目的">
            <a-select
              show-search
              :value="drivingSplitForm.driving_purpose"
              :style="'width: 100%'"
              @change="targetDrivingPurposeChange"
              :filter-option="filterOption"
            >
              <a-select-option
                v-for="item in drivingPurposes"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-config-provider :locale="locale">
            <a-form-model-item ref="drivingEndDate" label="到着日" prop="drivingEndDate">
              <a-date-picker placeholder="到着日" v-model="drivingEndDate" />
            </a-form-model-item>
          </a-config-provider>
          <a-config-provider :locale="locale">
            <a-form-model-item ref="drivingEndTime" label="到着時刻" prop="drivingEndTime">
              <a-time-picker placeholder="到着時刻" v-model="drivingEndTime" format="HH:mm:ss" />
            </a-form-model-item>
          </a-config-provider>
          <p>（元の到着日時: {{ drivingSplitOriginEnd }}）</p>

          <a-form-model-item ref="comment" label="備考" prop="comment">
            <a-input
              v-model="drivingSplitForm.comment"
              @blur="
                () => {
                  $refs.comment.onFieldBlur()
                }
              "
            />
          </a-form-model-item>

        </a-form-model>
      </div>
      <template slot="footer">
        <div class="clearfix">
          <div class="float-right">
            <a-button key="back" @click="handleCancelDrivingSplit">キャンセル</a-button>
            <a-button key="submit" type="primary" :loading="confirmDrivingSplitLoading" @click="handleOkDrivingSplit">更新</a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>

import Vue from 'vue'
import moment from 'moment'
import storeVue from '@/store'
import { objectData } from '@/services/object'
import * as VueGoogleMaps from 'vue2-google-maps'
import { mapStyle } from '@/services/mapStyle'
import jaJa from 'ant-design-vue/es/locale/ja_JP'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA-KH18hgZoLsK7-zOueBHrnA-4VZJmQVQ',
    libraries: 'places',
    region: 'JP',
    language: 'ja',
  },
})
Vue.config.productionTip = false

const icon05 = {
  url: 'resources/images/markers/05.png',
  size: { width: 24, height: 24, f: 'px', b: 'px' },
  scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
  anchor: { x: 12, y: 12 },
}
const icon15 = {
  url: 'resources/images/markers/15.png',
  size: { width: 24, height: 24, f: 'px', b: 'px' },
  scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
  anchor: { x: 12, y: 12 },
}
const icon = [
  [
    {
      url: 'resources/images/markers/10.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/20.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/30.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/40.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/50.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/60.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/70.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/80.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/90.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/100.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/110.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/120.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/6.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/7.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/8.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/9.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/10.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/11.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/start.png',
      size: { width: 32, height: 32, f: 'px', b: 'px' },
      scaledSize: { width: 32, height: 32, f: 'px', b: 'px' },
      anchor: { x: 16, y: 32 },
    },
    {
      url: 'resources/images/markers/goal.png',
      size: { width: 32, height: 32, f: 'px', b: 'px' },
      scaledSize: { width: 32, height: 32, f: 'px', b: 'px' },
      anchor: { x: 16, y: 32 },
    },
    {
      url: 'resources/images/markers/announce.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/6a.png',
      aurl: 'resources/images/markers/6a.png',
      size: { width: 36, height: 36, f: 'px', b: 'px' },
      scaledSize: { width: 36, height: 36, f: 'px', b: 'px' },
      anchor: { x: 18, y: 18 },
    },
    {
      url: 'resources/images/markers/7a.png',
      aurl: 'resources/images/markers/7a.png',
      size: { width: 36, height: 36, f: 'px', b: 'px' },
      scaledSize: { width: 36, height: 36, f: 'px', b: 'px' },
      anchor: { x: 18, y: 18 },
    },
    {
      url: 'resources/images/markers/8.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/9.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/10.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/11.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ], [
    {
      url: 'resources/images/markers/10a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/20a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/30a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/40a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/50a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/60a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/70a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/80a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/90a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/100a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/110a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
    {
      url: 'resources/images/markers/120a.png',
      size: { width: 24, height: 24, f: 'px', b: 'px' },
      scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
      anchor: { x: 12, y: 12 },
    },
  ],
]

const announcementContent = [
  ['一時停止を告知', '一時停止を告知'],
  ['踏切停止を告知', '踏切停止を告知'],
  ['速度規制の変更を告知'],
  ['速度超過を注意', '速度超過を注意', '速度超過を注意', '速度超過を注意', '速度超過を注意', '速度超過を注意'],
  ['ゾーン30内での走行を告知', 'ゾーン30での速度超過を注意'],
]

function padZero(v) {
  if (v < 10) {
    return '0' + v
  } else {
    return v
  }
}

export default {
  name: 'driveMap',
  data() {
    return {
      locale: jaJa,
      routeTarget: this.$route.meta.target,
      mapLoading: true,
      panoVisible: false,
      panoHeight: 60,
      edit: false,
      editBtnName: '編集',
      gMapStyle: 'width: 100%; height: 0vh;',
      panoStyle: 'width: 100%; height: 0vh;',
      resizeGMapStyle: null,
      resizePanoStyle: null,
      streetViewPosition: { lat: null, lng: null },
      streetViewPov: { heading: 0, pitch: 0 },
      loading: false,
      rawDataButtonLoading: false,
      mapStyle: mapStyle,
      activeMarkerIndex: null,
      driveData: Object.assign({}, objectData.driveData),
      gpsData: null,
      markers: [],
      lines: [],
      separationMarkers: [],
      separationIndexes: [],
      warningVisible: false,
      warningLoaded: false,
      warningMarkers: [],
      warningLines: [],
      announcements: {},
      announcementsSendDateTimes: [],
      announcementMarkers: [],
      announceCount: 0,
      gMap: null,
      bounds: null,
      totalGpsNum: 1,
      focusTime: '0',
      focusSpeed: '0',
      sliderValue: 0,
      sliderMarks: {},
      myRole: 3,
      showTrajectory: 0,
      activeTab: '1',
      reportValue: {
        distance: 0,
        driving_time_hour: 0,
        driving_time: 0,
      },
      infoWindow: {
        position: {
          lat: 0,
          lng: 0,
        },
        open: false,
        template: '',
      },
      reportTotalViolationNum: 0,
      reportTotalViolationAnnounceNum: 0,
      reportComplianceRate: 100,
      reportComplianceRateAnnounce: 100,
      reportViolationsColumns: [
        { title: '項目', dataIndex: 'name', key: 'name' },
        { title: '件数', dataIndex: 'num', key: 'num', scopedSlots: { customRender: 'num' } },
        { title: '件数 / 時間(h)', dataIndex: 'perHour', key: 'perHour', scopedSlots: { customRender: 'perHour' } },
        { title: '件数 / 距離(km)', dataIndex: 'perKm', key: 'perKm', scopedSlots: { customRender: 'perKm' } },
      ],
      reportViolations: [
        {
          key: 1,
          name: '速度超過(20 未満)',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 2,
          name: '速度超過(20 以上 25 未満)',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 3,
          name: '速度超過(25 以上 30 未満)',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 4,
          name: '速度超過(30 以上 50 未満)',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 5,
          name: '速度超過(50 以上)',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 6,
          name: '指定場所一時不停止等',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 7,
          name: '踏切不停止等',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 8,
          name: '通行禁止違反(一方通行)',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 9,
          name: '通行禁止違反(指定方向外進行禁止)',
          num: 0,
          perHour: 0,
          perKm: 0,
        },
        // {
        //   key: 10,
        //   name: '駐停車違反',
        //   num: 0,
        //   perHour: 0,
        //   perKm: 0,
        // },
        // {
        //   key: 11,
        //   name: '停車禁止違反',
        //   num: 0,
        //   perHour: 0,
        //   perKm: 0,
        // },
      ],
      reportViolationsAnnounceColumns: [
        { title: '項目', dataIndex: 'name', key: 'name' },
        { title: '件数', dataIndex: 'num', key: 'num', scopedSlots: { customRender: 'num' } },
        { title: 'アナウンス数', dataIndex: 'announce', key: 'announce', scopedSlots: { customRender: 'announce' } },
      ],
      reportViolationsAnnounce: [
        {
          key: 1,
          name: '速度超過',
          num: 0,
          announce: 0,
        },
        {
          key: 2,
          name: '指定場所一時不停止等',
          num: 0,
          announce: 0,
        },
        {
          key: 3,
          name: '踏切不停止等',
          num: 0,
          announce: 0,
        },
      ],

      drivingPurposes: [],
      reportSplitsColumns: [
        { title: '出発時間', scopedSlots: { customRender: 'start_datetime' } },
        { title: '行き先', dataIndex: 'name', key: 'name', scopedSlots: { customRender: 'name' } },
        { title: '運転の目的', dataIndex: 'driving_purpose', key: 'driving_purpose', scopedSlots: { customRender: 'driving_purpose' } },
        { title: '到着時間', scopedSlots: { customRender: 'end_datetime' } },
        { title: '備考', dataIndex: 'comment', key: 'comment', scopedSlots: { customRender: 'comment' } },
        { title: '編集', key: 'edit', class: 'no-print', scopedSlots: { customRender: 'edit' } },
      ],
      reportSplits: [],
      googleForward: null,
      myUseAnalyze: 0,
      myUseAnnounce: 0,
      isFleetPlan: true,

      visibleDrivingSplit: false,
      drivingSplitForm: {
        start_datetime: '',
        name: '',
        driving_purpose: '0',
        end_datetime: '',
        comment: '',
      },
      drivingStartDate: moment(),
      drivingStartTime: moment(),
      drivingEndDate: moment(),
      drivingEndTime: moment(),
      drivingSplitOriginStart: '',
      drivingSplitOriginEnd: '',
      drivingSplitRules: {
        name: [
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ],
        comment: [
          { max: 1024, message: '1024文字までです。', trigger: 'blur' },
        ],
      },
      targetDrivingSplitId: 0,
      confirmDrivingSplitLoading: false,
    }
  },
  computed: {
    storeMyRole: function() { return storeVue.getters.role },
    storeShowTrajectory: function() { return storeVue.getters.show_trajectory },
  },
  watch: {
    storeMyRole: function(val) { this.myRole = val },
    storeShowTrajectory: function(val) { this.showTrajectory = val },
    panoVisible(val) {
      if (val) {
        if (this.resizeGMapStyle) {
          this.gMapStyle = this.resizeGMapStyle
          this.panoStyle = this.resizePanoStyle
        } else {
          this.gMapStyle = 'width: 100%; height: 45vh;'
          this.panoStyle = 'width: 100%; height: 35vh;'
        }
      } else {
        this.gMapStyle = 'width: 100%; height: 80vh;'
        this.panoStyle = 'width: 100%; height: 0vh;'
      }
      if (this.activeTab === '3') {
        this.gMapStyle = 'width: 100%; height: 300px;'
        this.panoStyle = 'width: 100%; height: 0vh;'
      } else if (this.activeTab === '4') {
        this.gMapStyle = 'width: 100%; height: 0px;'
        this.panoStyle = 'width: 100%; height: 0vh;'
      }
    },
    gpsData(val) {
      if (val.array.length) {
        const _this = this
        var separationIndexesNow = 0
        var announceIndex = 0
        const perIcon = parseInt(val.array.length / 10)
        this.totalGpsNum = val.array.length - 1
        this.sliderMarks[0] = moment(val.array[0].time, 'YYYY/MM/DD hh:mm:ss').add(9, 'hours').format('HH:mm:ss')
        this.sliderMarks[val.array.length - 1] = moment(val.array[val.array.length - 1].time, 'YYYY/MM/DD hh:mm:ss').add(9, 'hours').format('HH:mm:ss')
        this.$gmapApiPromiseLazy().then(() => {
          this.googleForward = google.maps.SymbolPath.FORWARD_OPEN_ARROW // eslint-disable-line
          let bounds = new google.maps.LatLngBounds() // eslint-disable-line
          let markerIndex = 0
          const lineSymbol = {
            path: 'M 0,-1 0,1',
            // strokeOpacity: 0.6,
            strokeOpacity: 0,
            scale: 4,
          }

          let momentBefore = moment(val.array[0].time, 'YYYY/MM/DD hh:mm:ss')
          let valBefore = val.array[0]
          var startFlag = true

          for (let i = 0; i < val.array.length - 1; i++) {
            let icons = null
            while (1) {
              if (markerIndex < this.markers.length && valBefore.time === this.markers[markerIndex].time) {
                if (this.markers[markerIndex].hide === 0) {
                  this.sliderMarks[i] = {
                    style: {
                      'background-image': 'url(' + this.markers[markerIndex].icon.url + ')',
                      'background-repeat': 'no-repeat',
                      'background-size': '25px 25px',
                      'margin-left': '8px',
                      padding: '20px',
                    },
                    label: ' ',
                  }
                  this.markers[markerIndex].sliderMakerIndex = i
                }
                markerIndex++
              } else {
                break
              }
            }

            if (i + 1 === this.separationIndexes[separationIndexesNow]) {
              startFlag = true
              this.separationMarkers.push({ position: { lat: valBefore.lat, lng: valBefore.lon }, icon: icon[2][1] })
              i++
              separationIndexesNow++
              valBefore = val.array[i + 1]
              if (valBefore) momentBefore = moment(val.array[i + 1].time, 'YYYY/MM/DD hh:mm:ss')
              continue
            }

            if (val.array[i + 1].accuracy && val.array[i + 1].accuracy !== 1) continue
            if (startFlag) {
              this.separationMarkers.push({ position: { lat: valBefore.lat, lng: valBefore.lon }, icon: icon[2][0] })
              startFlag = false
            }

            const momentTarget = moment(val.array[i + 1].time, 'YYYY/MM/DD hh:mm:ss')

            if (this.announcementsSendDateTimes.includes(momentTarget.format('YYYY-MM-DD HH:mm:ss'))) {
              const tmpDateTime = momentTarget.format('YYYY-MM-DD HH:mm:ss')
              let announceIcon = icon[2][2]
              if (_this.announcements[tmpDateTime]['code'] === 4) {
                const iconNum = (_this.announcements[tmpDateTime]['limitSpeed'] / 10) - 1
                announceIcon = icon[4][iconNum]
              }
              this.announcementMarkers.push({
                id: announceIndex,
                sliderMakerIndex: i + 1,
                time: tmpDateTime,
                position: { lat: val.array[i + 1].lat, lng: val.array[i + 1].lon },
                angle: val.array[i + 1].angle,
                icon: announceIcon,
                code: _this.announcements[tmpDateTime]['code'],
                comment: announcementContent[_this.announcements[tmpDateTime]['code'] - 1][_this.announcements[tmpDateTime]['codeSub'] - 1],
                speed: val.array[i + 1].speed.toFixed(2),
                limitSpeed: _this.announcements[tmpDateTime]['limitSpeed'],
              })
              announceIndex++
            }

            if (i % perIcon === 0) {
              icons = { icons: [{ icon: { path: 'M0 0 l0 -2 l0 -2 l-2 6 m2 -6 l2 6' } }] }
              // icons = { icons: [{ icon: { path: this.googleForward } }] }
            } else if (momentTarget.diff(momentBefore, 'seconds') > 10) {
              icons = {
                icons: [{
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '15px',
                }],
                strokeOpacity: 0,
              }
            }

            this.lines.push({
              path: [
                { lat: valBefore.lat, lng: valBefore.lon },
                { lat: val.array[i + 1].lat, lng: val.array[i + 1].lon },
              ],

              icons: icons,
            })
            bounds.extend(new google.maps.LatLng(valBefore.lat, valBefore.lon)) // eslint-disable-line
            momentBefore = momentTarget
            valBefore = val.array[i + 1]
          }
          this.separationMarkers.push({ position: { lat: val.array[val.array.length - 1].lat, lng: val.array[val.array.length - 1].lon }, icon: icon[2][1] })
          this.bounds = bounds
          if (this.gMap) {
            this.gMap.fitBounds(bounds)
          }
        })
      }
    },
    sliderValue(val) {
      this.focusTime = this.gpsData.array[val].time
      this.focusSpeed = this.gpsData.array[val].speed.toFixed(2)
      this.streetViewPosition = { lat: this.gpsData.array[val].lat, lng: this.gpsData.array[val].lon }
      this.streetViewPov = { heading: this.gpsData.array[val].angle, pitch: 0 }
      this.gMap.setCenter(this.streetViewPosition)
      for (let i = 0; i < this.markers.length; i++) {
        if (this.gpsData.array[val].time === this.markers[i].time) {
          if (this.activeMarkerIndex != null) {
            this.markers[this.activeMarkerIndex].isActive = false
          }
          this.markers[i].isActive = true
          this.activeMarkerIndex = i
        }
      }
    },
    driveData: {
      handler: function(val, pri) {
        this.reportValue.distance = parseFloat(val.distance) / 1000
        const h = val.driving_time / 3600 | 0
        const m = val.driving_time % 3600 / 60 | 0
        const s = val.driving_time % 60
        this.reportValue.driving_time_hour = parseFloat(val.driving_time) / 3600
        if (h) {
          this.reportValue.driving_time = h + ':' + padZero(m) + ':' + padZero(s)
        } else if (m) {
          this.reportValue.driving_time = m + ':' + padZero(s)
        } else {
          this.reportValue.driving_time = s + '秒'
        }
        this.reportTotalViolationNum = 0
        for (let i = 0; i < this.reportViolations.length; i++) {
          const targetType = i + 1
          const num = val.violations.filter(v => v.type === targetType && v.hide === 0).length
          this.reportTotalViolationNum += num
          this.reportViolations[i].num = num
          this.reportViolations[i].perHour = (num / this.reportValue.driving_time_hour).toFixed(3)
          this.reportViolations[i].perKm = (num / this.reportValue.distance).toFixed(3)
        }
        if (this.driveData.regulation_num === 0) this.reportComplianceRate = 100
        else this.reportComplianceRate = ((this.driveData.regulation_num - this.reportTotalViolationNum) * 100 / this.driveData.regulation_num).toFixed(3)

        this.reportTotalViolationAnnounceNum = val.violations.filter(v => v.hide === 0 && v.announced_flag === 1).length
        this.reportViolationsAnnounce[0].num = val.violations.filter(v => v.type <= 5 && v.hide === 0 && v.announced_flag === 1).length
        this.reportViolationsAnnounce[1].num = val.violations.filter(v => v.type === 6 && v.hide === 0 && v.announced_flag === 1).length
        this.reportViolationsAnnounce[2].num = val.violations.filter(v => v.type === 7 && v.hide === 0 && v.announced_flag === 1).length
        if (this.announceCount === 0) this.reportComplianceRateAnnounce = 100
        else this.reportComplianceRateAnnounce = ((this.announceCount - this.reportTotalViolationAnnounceNum) * 100 / this.announceCount).toFixed(3)
      },
      deep: true,
    },
  },
  created() {
    const _this = this
    let getDriveDataPath
    let getDriveDataAnnouncementsPath
    let getData
    if (this.routeTarget === 'drive_data') {
      getDriveDataPath = 'drive_data/' + this.$route.params.id
      getDriveDataAnnouncementsPath = 'drive_data/' + this.$route.params.id + '/announcements_announced'
    } else if (this.routeTarget === 'user') {
      getDriveDataPath = 'drive_data/user/' + this.$route.params.user_id
      getDriveDataAnnouncementsPath = 'drive_data/user/' + this.$route.params.user_id + '/announcements_announced'
      getData = {
        date: this.$route.params.date,
      }
    } else if (this.routeTarget === 'vehicle') {
      getDriveDataPath = 'drive_data/vehicle/' + this.$route.params.vehicle_id
      getDriveDataAnnouncementsPath = 'drive_data/vehicle/' + this.$route.params.vehicle_id + '/announcements_announced'
      getData = {
        date: this.$route.params.date,
      }
    }

    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(response => {
      _this.myUseAnalyze = response.use_analyze
      _this.myUseAnnounce = response.use_announce
      if (response.company_plan !== 6) _this.isFleetPlan = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })

    const resultDrivingPurposes = Vue.prototype.$api.send('get', 'driving_purposes')
    resultDrivingPurposes.then(response => {
      _this.drivingPurposes = response
      _this.drivingPurposes.unshift({
        id: 0,
        name: '未選択',
      })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 運行目的取得に失敗しました。',
        })
      })

    const resultAnnouncements = Vue.prototype.$api.send('get', getDriveDataAnnouncementsPath, getData)
    resultAnnouncements.then(response => {
      // console.log('announcements', response)
      _this.announcementsSendDateTimes = response.map(e => {
        if (e.code) {
          _this.announcements[e.send_date_time.trim()] = {
            code: e.code,
            codeSub: e.code_sub,
            limitSpeed: e.limit_speed,
          }
          return e.send_date_time
        }
      })
      for (let i = 0; i < response.length; i++) {
        if (response[i].code === 1 || response[i].code === 2) _this.announceCount++
        if (response[i].code === 1) _this.reportViolationsAnnounce[1].announce++
        if (response[i].code === 2) _this.reportViolationsAnnounce[2].announce++
        if (response[i].code >= 4) _this.reportViolationsAnnounce[0].announce++
      }

      const result = Vue.prototype.$api.send('get', getDriveDataPath, getData)
      result.then(response => {
        // console.log('driveData', response)
        let violations = []
        if (this.routeTarget === 'drive_data') {
          violations = response.violations
          this.gpsData = JSON.parse(response.gps_data)
          delete response.gps_data
          this.driveData = response
          this.reportSplits = response.driving_splits.map(e => {
            return {
              id: e.id,
              comment: e.comment,
              driving_purpose: e.driving_purpose_id ? e.driving_purpose_id : 0,
              start_datetime: e.start_datetime,
              start_datetime_origin: e.start_datetime_origin,
              end_datetime: e.end_datetime,
              end_datetime_origin: e.end_datetime_origin,
              name: e.name,
            }
          })
        } else {
          let gpsData = []
          let reportSplits = []
          var tmpDriveData = Object.assign({}, objectData.driveData)
          console.log('before loop DriveData', tmpDriveData)
          tmpDriveData.time_start = '2999-12-31'
          tmpDriveData.time_end = '1999-01-01'
          var vehicleNumberSet = new Set()
          response.forEach(function(val) {
            violations = violations.concat(val.violations)
            const tmpSplit = val.driving_splits.map(e => {
              return {
                id: e.id,
                comment: e.comment,
                driving_purpose: e.driving_purpose_id,
                start_datetime: e.start_datetime,
                start_datetime_origin: e.start_datetime_origin,
                end_datetime: e.end_datetime,
                end_datetime_origin: e.end_datetime_origin,
                name: e.name,
              }
            })
            reportSplits = reportSplits.concat(tmpSplit)
            const tmpGps = JSON.parse(val.gps_data)
            if (tmpGps) {
              gpsData = gpsData.concat(tmpGps.array)
              _this.separationIndexes.push(gpsData.length)
            }
            vehicleNumberSet.add(val.vehicle_number)
            tmpDriveData.date = val.date
            tmpDriveData.user_name = val.user_name
            tmpDriveData.branch_name = val.branch_name
            tmpDriveData.vehicle_name = val.vehicle_name
            tmpDriveData.driving_time += val.driving_time
            tmpDriveData.distance += val.distance
            tmpDriveData.violation_count += val.violation_count
            tmpDriveData.regulation_num += val.regulation_num
            if (tmpDriveData.time_start > val.time_start) tmpDriveData.time_start = val.time_start
            if (tmpDriveData.time_end < val.time_end) tmpDriveData.time_end = val.time_end
          })
          tmpDriveData.vehicle_number = Array.from(vehicleNumberSet).join(', ')
          this.reportSplits = reportSplits
          this.sortSplits()
          tmpDriveData.compliance_rate = tmpDriveData.regulation_num ? ((tmpDriveData.regulation_num - tmpDriveData.violation_count) / tmpDriveData.regulation_num) * 100 : 100
          tmpDriveData.violations = violations
          console.log('after loop DriveData', tmpDriveData)
          _this.driveData = tmpDriveData
          _this.gpsData = { array: gpsData }
        }
        violations.forEach(function(val) {
          let markerIcon
          if (val.type <= 5) {
            if (val.limit_speed === 15) markerIcon = icon15
            else if (val.limit_speed === 5) markerIcon = icon05
            else markerIcon = icon[0][(val.limit_speed / 10) - 1]
          } else {
            if (val.announced_flag === 1) markerIcon = icon[3][val.type - 6]
            else markerIcon = icon[1][val.type - 6]
          }
          _this.markers.push({ title: val.description, position: { lat: val.latitude, lng: val.longitude }, icon: markerIcon, isActive: false, comment: val.description, angle: parseInt(val.angle), hide: val.hide, violationId: val.id, loading: false, time: val.date.replace(/-/g, '/') + ' ' + val.time, speed: parseFloat(val.speed).toFixed(1) })
        })

        console.log('violations', violations)
        this.mapLoading = false
        this.gMapStyle = 'width: 100%; height: 80vh;'
      })
    })
  },
  filters: {
    momentDateTime: function (dateTime) {
      return moment(dateTime, 'YYYY-MM-DD hh:mm:ss').format('YYYY/MM/DD HH:mm:ss')
    },
    momentDate: function (date) {
      return moment(date).format('YYYY/MM/DD')
    },
    momentTime: function (dateTime) {
      return moment(dateTime, 'YYYY-MM-DD hh:mm:ss').format('HH:mm:ss')
    },
    momentTimeAdd9Hour: function (dateTime) {
      return moment(dateTime, 'YYYY/MM/DD hh:mm:ss').add(9, 'hours').format('HH:mm:ss')
    },
    momentDateTimeAdd9Hour: function (dateTime) {
      return moment(dateTime, 'YYYY/MM/DD hh:mm:ss').add(9, 'hours').format('YYYY/MM/DD HH:mm:ss')
    },
  },
  methods: {
    sortSplits() {
      this.reportSplits.sort(function(a, b) {
        if (a.start_datetime < b.start_datetime) return -1
        if (a.start_datetime > b.start_datetime) return 1
        return 0
      })
    },
    filterOption(input, option) { return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 },
    setView(index) {
      if (this.activeMarkerIndex != null) {
        this.markers[this.activeMarkerIndex].isActive = false
      }
      this.markers[index].isActive = true
      this.activeMarkerIndex = index

      const lat = this.markers[index].position.lat
      const lng = this.markers[index].position.lng
      const time = this.markers[index].time
      const speed = this.markers[index].speed
      const title = this.markers[index].title
      this.infoWindow.position = { lat: lat, lng: lng }
      this.infoWindow.template = `<b>${moment(time, 'YYYY/MM/DD hh:mm:ss').add(9, 'hours').format('YYYY/MM/DD HH:mm:ss')}(${speed}km/h)</b><br>${title}`
      this.infoWindow.open = true

      this.panoVisible = true
      this.streetViewPosition = { lat: lat, lng: lng }
      this.streetViewPov = { heading: this.markers[index].angle, pitch: 0 }
      this.sliderValue = this.markers[index].sliderMakerIndex - 2 >= 0 ? this.markers[index].sliderMakerIndex - 2 : this.markers[index].sliderMakerIndex
    },
    setViewAnnounce(index) {
      const obj = this.announcementMarkers.find(e => e.id === index)
      const lat = obj.position.lat
      const lng = obj.position.lng
      const time = obj.time
      const comment = obj.comment
      const angle = obj.angle
      const sliderMakerIndex = obj.sliderMakerIndex
      this.infoWindow.position = { lat: lat, lng: lng }
      this.infoWindow.template = `<b>${moment(time, 'YYYY/MM/DD hh:mm:ss').add(9, 'hours').format('YYYY/MM/DD HH:mm:ss')}</b><br>${comment}`
      this.infoWindow.open = true

      this.panoVisible = true
      this.streetViewPosition = { lat: lat, lng: lng }
      this.streetViewPov = { heading: angle, pitch: 0 }
      this.sliderValue = sliderMakerIndex
    },
    setViewWarning(index) {
      const lat = this.warningMarkers[index].position.lat
      const lng = this.warningMarkers[index].position.lng
      const description = this.warningMarkers[index].description
      this.infoWindow.position = { lat: lat, lng: lng }
      this.infoWindow.template = `<b>${description}</b>`
      this.infoWindow.open = true
    },
    closeStreetView() { this.panoVisible = false },
    toggleEdit() {
      this.edit = !this.edit
      this.editBtnName = this.edit ? '終了' : '編集'
    },
    toggleHide(index) {
      this.markers[index].loading = true
      const result = Vue.prototype.$api.send('post', 'drive_data/toggle_violation/' + this.markers[index].violationId)
      const _this = this
      result.then(response => {
        this.markers[index].hide = !this.markers[index].hide
        _this.$notification['success']({
          message: '違反を変更しました。',
        })
        this.markers[index].loading = false
      })
    },
    sliderFormatter(val) { return this.gpsData ? moment(this.gpsData.array[val].time, 'YYYY/MM/DD hh:mm:ss').add(9, 'hours').format('YYYY/MM/DD HH:mm:ss') : 0 },
    prevSlider() { if (this.sliderValue - 1 >= 0) this.sliderValue-- },
    nextSlider() { if (this.sliderValue + 1 < this.gpsData.array.length) this.sliderValue++ },
    tabChange(val) {
      this.activeTab = val
      if (val === '1') {
        this.gMapStyle = 'width: 100%; height: 80vh;'
        this.panoStyle = 'width: 100%; height: 0vh;'
        this.$refs.defaultMapParent.appendChild(this.$refs.gMap.$el)
      } else if (val === '3') {
        this.panoVisible = false
        this.gMapStyle = 'width: 100%; height: 300px;'
        this.panoStyle = 'width: 100%; height: 0vh;'
        this.$refs.repoMapParent.appendChild(this.$refs.gMap.$el)
      } else if (val === '4') {
        this.panoVisible = false
        this.$refs.repoMapParent.appendChild(this.$refs.gMap.$el)
      }
    },
    print() { window.print() },
    rawData() {
      this.rawDataButtonLoading = true
      const result = Vue.prototype.$api.send('get', 'drive_data/' + this.$route.params.id + '/raw')
      result.then(response => {
        var blob = new Blob([response], { type: 'text/plain' })
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'raw_' + this.$route.params.id + '.csv'
        link.click()
        this.rawDataButtonLoading = false
      })
    },
    resizeEnd(e) {
      const wrapPosition = this.$refs.mapWrapper.getBoundingClientRect()
      const dragPosition = (e.pageY - (wrapPosition.top + window.pageYOffset)) / (wrapPosition.bottom - wrapPosition.top)
      this.panoHeight = 80 * dragPosition
      if (this.panoHeight > 60) this.panoHeight = 60
      else if (this.panoHeight < 10) this.panoHeight = 10

      this.gMapStyle = 'width: 100%; height: ' + (80 - this.panoHeight) + 'vh;'
      this.resizeGMapStyle = 'width: 100%; height: ' + (80 - this.panoHeight) + 'vh;'
      this.panoStyle = 'width: 100%; height: ' + this.panoHeight + 'vh;'
      this.resizePanoStyle = 'width: 100%; height: ' + this.panoHeight + 'vh;'
      Vue.$gmapDefaultResizeBus.$emit('resize')
    },
    onChangeWarning(e) {
      const checked = e.target.checked
      if (checked) {
        if (!this.warningLoaded) {
          const warningIcon = {
            url: 'resources/images/markers/warning.png',
            size: { width: 24, height: 24, f: 'px', b: 'px' },
            scaledSize: { width: 24, height: 24, f: 'px', b: 'px' },
            anchor: { x: 12, y: 12 },
          }
          const requestMarkers = new XMLHttpRequest() // HTTPでファイルを読み込む
          requestMarkers.addEventListener('load', (event) => { // ロードさせ実行
            let response = event.target.responseText // 受け取ったテキストを返す
            response = response.split('\n')
            for (var i = 0; i < response.length; i++) {
              if (response[i]) {
                const result = response[i].split(',')
                this.warningMarkers.push({ position: { lat: parseFloat(result[0]), lng: parseFloat(result[1]) }, icon: warningIcon, description: result[2] })
              }
            }
            this.warningLoaded = true
          })
          requestMarkers.open('GET', 'resources/warning/markerList.csv', true) // csvのパスを指定
          requestMarkers.send()

          const requestLines = new XMLHttpRequest()
          requestLines.addEventListener('load', (event) => {
            let response = event.target.responseText
            response = response.split('\n')
            const lineIcons = {
              strokeOpacity: 1,
              strokeWeight: 3,
              strokeColor: '#ED4448',
            }

            for (var i = 0; i < response.length; i++) {
              if (response[i]) {
                const result = response[i].split(',')
                this.warningMarkers.push({ position: { lat: parseFloat(result[0]), lng: parseFloat(result[1]) }, icon: warningIcon, description: result[4] })
                this.warningMarkers.push({ position: { lat: parseFloat(result[2]), lng: parseFloat(result[3]) }, icon: warningIcon, description: result[4] })
                this.warningLines.push({
                  path: [
                    { lat: parseFloat(result[0]), lng: parseFloat(result[1]) },
                    { lat: parseFloat(result[2]), lng: parseFloat(result[3]) },
                  ],
                  icons: lineIcons,
                })
              }
            }
            this.warningLoaded = true
          })
          requestLines.open('GET', 'resources/warning/lineList.csv', true)
          requestLines.send()
        } else {
          this.$refs.warningLine.forEach(function(val) {
            val.options.strokeOpacity = 1
          })
        }
        this.warningVisible = true
      } else {
        this.$refs.warningLine.forEach(function(val) {
          val.options.strokeOpacity = 0
        })
        this.warningVisible = false
      }
    },
    violationText: function (text) { return text.replace(/(\d+km\/h)/, '<b>$1</b>') },

    targetDrivingPurposeChange(val) {
      this.drivingSplitForm.driving_purpose = val
    },
    handleCancelDrivingSplit() { this.visibleDrivingSplit = false },
    updateSplit(id) {
      const targetSplit = this.reportSplits.find(e => e.id === id)
      this.targetDrivingSplitId = id
      this.drivingSplitForm.name = targetSplit.name
      this.drivingSplitForm.start_datetime = targetSplit.start_datetime
      this.drivingSplitForm.name = targetSplit.name
      this.drivingSplitForm.driving_purpose = targetSplit.driving_purpose
      this.drivingSplitForm.end_datetime = targetSplit.end_datetime
      this.drivingSplitForm.comment = targetSplit.comment
      this.visibleDrivingSplit = true
      const targetStartDateTime = moment(targetSplit.start_datetime, 'YYYY-MM-DD HH:mm:ss')
      const targetEndDateTime = moment(targetSplit.end_datetime, 'YYYY-MM-DD HH:mm:ss')
      this.drivingStartDate = targetStartDateTime
      this.drivingStartTime = targetStartDateTime
      this.drivingEndDate = targetEndDateTime
      this.drivingEndTime = targetEndDateTime
      this.drivingSplitOriginStart = targetSplit.start_datetime_origin
      this.drivingSplitOriginEnd = targetSplit.end_datetime_origin
    },
    handleOkDrivingSplit() {
      const _this = this
      this.confirmDrivingSplitLoading = true
      const actionType = 'put'
      const bodyData = {
        id: this.targetDrivingSplitId,
        start_datetime: this.drivingStartDate.format('YYYY-MM-DD') + ' ' + this.drivingStartTime.format('HH:mm:ss'),
        name: this.drivingSplitForm.name,
        driving_purpose: this.drivingSplitForm.driving_purpose,
        end_datetime: this.drivingEndDate.format('YYYY-MM-DD') + ' ' + this.drivingEndTime.format('HH:mm:ss'),
        comment: this.drivingSplitForm.comment,
      }

      this.$refs.drivingSplitRef.validate(valid => {
        const sendDrivingSplit = Vue.prototype.$api.send(actionType, 'driving_split', bodyData)
        sendDrivingSplit.then(response => {
          _this.$notification['success']({
            message: '運転日報を更新しました。',
          })
          var targetUpdateDrivingSplit = this.reportSplits.find(e => e.id === this.targetDrivingSplitId)
          targetUpdateDrivingSplit.start_datetime = bodyData.start_datetime
          targetUpdateDrivingSplit.name = this.drivingSplitForm.name
          targetUpdateDrivingSplit.driving_purpose = this.drivingSplitForm.driving_purpose
          targetUpdateDrivingSplit.end_datetime = bodyData.end_datetime
          targetUpdateDrivingSplit.comment = this.drivingSplitForm.comment
          this.visibleDrivingSplit = false
          this.confirmDrivingSplitLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.confirmDrivingSplitLoading = false
            console.log(error)
          })
      })
    },
  },
  mounted() {
    const _this = this
    this.$refs.gMap.$mapPromise.then((map) => {
      this.gMap = map
      if (this.bounds) {
        map.fitBounds(this.bounds)
      }
      map.setStreetView(this.$refs.pano.$panoObject)
      this.$refs.pano.$panoObject.addListener('position_changed', function() {
        _this.panoVisible = true
      })
    })
    this.myRole = storeVue.getters.role
    this.showTrajectory = storeVue.getters.show_trajectory
  },
}

</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
<style>
@media print {
  header, .ant-layout-sider, .no-print, .ant-message {
    display: none;
  }
}
.ant-list-item.hide {
  background: #d9d9d9;
}
.ant-list-item.active {
  background: #e6f7ff;
}
#googleMap div > img {
  position: absolute;
}
</style>
